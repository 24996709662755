<template>
    <main class="ztb_main policy_main info_details">
      <div class="ztb_box" >
        <div class="title">{{newsDetail.title}}</div>
        <div class="author" @click="showAuthorInfo(authorInfo.id)">
          <a href="javascript:void(0);" class="img_box db">
            <img :src="getImgUrl(authorInfo.image)"  @error="errPic($event,'avatar')">
          </a>
          <a href="javascript:void(0);">{{newsDetail.authorName}}</a>
          <span v-formatTime>{{newsDetail.createdTime}}</span>
        </div>
        <div class="intro">
          <span class="bold">文章摘要</span><span class="intro_detail">{{newsDetail.descrption}}</span>
      </div>
      <div class="details passage_details" v-html="newsDetail.content"></div>
      </div>
      <div class="ztb_latest">
        <div class="passage_list">
          <a href="javascript:void(0);" class="db"  @click="showAuthorInfo(authorInfo.id)">
              <div class="img_box ovh auto mb10">
                <img :src="getImgUrl(authorInfo.image)"  @error="errPic($event,'avatar')">
              </div>
              <h2 class="tc f18 mb10">{{newsDetail.authorName}}</h2>
              <div class="short f14 tc">{{authorInfo.authorIntroduce}}</div>
          </a>
          <div class="heading">最新文章</div>
          <ul>
            <li  v-for="item in authorInfo.articleList" :key="item.id" >
              <a href="javascript:void(0);" @click="getNewsDetail(item.id)">{{item.title}}</a>
            </li>
            
            
          </ul>
        </div>

        <div class="sidebar_box">
        <div class="title">
         <span>热文排行</span>
        </div>
        <ul>
          <li v-for="(item,index) in topArticles" @click="getNewsDetail(item.id)" :key="item">
            <a class="cup">
              <i>{{index+1}}</i>
              <img v-show="index===0" :src="getImgUrl(item.image)" @error="errPic($event)" class="w100p">
              <p>{{item.title}}</p>
            </a>
          </li>
        </ul>
        </div>



      </div>
    </main>
</template>
<script setup>
import {useRouter} from "vue-router"
import useNewsDetail from "@/composable/news/useNewsDetail"
import useHotAuthor_Article from "@/composable/news/useHotAuthor_Article"
import {getImgUrl,errPic} from "@/utils/imageRelated";

const router=useRouter()

        const {newsDetail,getNewsDetail,authorInfo}=useNewsDetail()
        const {topArticles}=useHotAuthor_Article()


        const showAuthorInfo=(id)=>{
          router.push({
            name:"AuthorDetail",
            query:{id}
          })
        }
</script>
<style lang="less" scoped>
.top-article{
    display: flex;
    margin-top: 27px;
    cursor: pointer;
  }
  .top-article-container{
    margin-left: 27px;
    display: flex;
    flex-direction: column;
  }
  .top-article-container>span:nth-child(1){
    font-weight: bold;
  }
  .top-article-container>span:nth-child(2){
    margin-top: 17px;
    flex-grow: 1;
  }
  .top-article>img{
    width: 186px;
    height: 116px;
  }

// 2021 10 20
/deep/.passage_details p{
  margin-top:20px;
  font-size: 14px;
  text-align: left;
}

/deep/.passage_details section{
  text-align: left;
  margin-top:20px;
  font-size: 14px;
}

/deep/.passage_details h3,.passage_details h2{
 font-size: 16px;
 font-weight: bold; 
 margin:30px 0 10px;
}

/deep/.passage_details h2{
 font-size: 16px;
 font-weight: bold; 
 margin:30px 0 10px;
}




.info_details{padding-top: 0;}

.info_details .ztb_box .details{
  padding-top:0;
}

.info_details .ztb_box .intro .intro_detail{color:#666;font-weight: 300;}
.cup{cursor:pointer;}

</style>