import {onMounted, reactive} from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useGetNewsDetail(){
    const route=useRoute()
    const id=route.query.id

    const newsDetail=reactive({})
    const getNewsDetail=async (id)=>{
        document.documentElement.scrollTop=0;

        const [err,data]=await $api.article.getArticleDetail({id})
        if(!data.succeeded) return;
       data.data.content= data.data.content.replace(/<img/g,"<img style='width:100%;height:100%'")
        Object.assign(newsDetail,data.data);
        getAuthorInfo(newsDetail.author)
    }

    const authorInfo=reactive({})
    const getAuthorInfo=async(id)=>{
        const [err,data]=await $api.article.getAuthorInfo({AuthorId:id})
        if(!data.succeeded) return;
        Object.assign(authorInfo,data.data)
    }
    onMounted(()=>{
        getNewsDetail(id)
    })
    return {
        newsDetail,
        getNewsDetail,
        authorInfo
    }
}
